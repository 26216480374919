// Using rgb of InceptiaGreen '#006D3F' from the theme
export const inceptiaGreenAlphaColors = {
  hover: 'rgba(0, 109, 63, 0.06)',
  selected: 'rgba(0, 109, 63, 0.10)',
  selectedHover: 'rgba(0, 109, 63, 0.14)'
};

export const pageHeaderSX = {
  fontSize: '2rem',
  fontWeight: '500'
};

const cardPaperSX = {
  borderRadius: 2,
  boxShadow: '0px 1px 2px 2px rgba(207, 216, 220, 0.32)',
};
export const cardSX = {
  paper: {
    ...cardPaperSX
  },
  paperMinHeight: {
    ...cardPaperSX,
    height: '100%',
    minHeight: '400px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    py:3
  },
  emptyStateBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1
  }
};

export const menuSX = {
  menuList: {
    minWidth: '180px',
    textAlign: 'right'
  },
  menuItemText: {
    fontWeight: '500'
  },
  divider: {
    '&.MuiDivider-root': {
      my: 0
    }
  }
};

export const listItemSX = {
  '&.MuiListItemButton-root:hover, &.MuiListItemButton-root:focus': {
    backgroundColor: inceptiaGreenAlphaColors.hover
  },
  '&.Mui-selected': {
    backgroundColor: inceptiaGreenAlphaColors.selected,
    color: 'InceptiaGreen.main',
    '& .MuiListItemIcon-root': {
      color: 'InceptiaGreen.main'
    }
  },
  '&.Mui-selected:hover, &.Mui-selected:focus': {
    backgroundColor: inceptiaGreenAlphaColors.selectedHover
  }
};
