import { useEffect, useState } from 'react'
import { Alert, AlertTitle, Container, Paper, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { cardSX, pageHeaderSX } from '../utilities/CSS';
import KeycloakService from '../services/KeycloakService';
import ReportBinders from '../components/ReportBinders';

const NoReportsAvailable = () => {
  return (
    // Max width on the alert is dependent upon the content of the alert - adjust as needed
    <Alert icon={<InfoOutlinedIcon />} severity='success' sx={{ maxWidth: '675px' }}>
      <AlertTitle sx={{ mb: 1.5 }}>No Reports Available</AlertTitle>
      <Typography variant='body1' sx={{ mb: 0.75 }}>No reports are currently available for your account.</Typography>
      <Typography variant='body1'>If you have any questions or concerns, please don't hesitate to reach out to us.</Typography>
    </Alert>
  );
};

export default function AdminReports() {
  const [hasReportsAccess, setHasReportsAccess] = useState(false);

  useEffect(() => {
    if (['Admin', 'Manager', 'InstitutionAdmin'].some(g => KeycloakService.hasGroup([g]))) {
      setHasReportsAccess(true);
    }
  }, []);   

  return (<>
    <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4.5 }}>Available Reports</Typography>

    <Paper sx={cardSX.paperMinHeight}>
      <Container sx={{ py: 4.5 }}>
        { ! hasReportsAccess && (<NoReportsAvailable />)}

        {hasReportsAccess && (<ReportBinders />)}
      </Container>
    </Paper>
  </>);
}
