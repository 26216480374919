import Keycloak from 'keycloak-js';

const _kc: Keycloak = new Keycloak({
    realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
    url: process.env.REACT_APP_KEYCLOAK_URL || '',
    clientId: process.env.REACT_APP_KEYCLOAK_DASHBOARD_CLIENT || ''
});

const initKeycloak = (onAuthenticatedCallback: () => void): void => {
  const url = new URL(window.location.href.toString());

  const orgPurl = url.searchParams.get("purl") || null;
  if (orgPurl != null) {
      // save purl in localstorage
      localStorage.setItem('formsPurl', orgPurl);
  }

  let formUuid = url.searchParams.get("formUuid") || null;
  if (formUuid == null) {
      formUuid = localStorage.getItem('formsFormUuid') || null;
  } else {
      localStorage.setItem('formsFormUuid', formUuid);
  }

  let studnetLinkUUID = url.searchParams.get("userLink") || null;
  if (studnetLinkUUID == null) {
      studnetLinkUUID = localStorage.getItem('formsUserLink') || null;
  } else {
      localStorage.setItem('formsUserLink', studnetLinkUUID);
  }

  _kc.init({
    onLoad: 'login-required'
  })
    .then((authenticated: boolean) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
  _kc.onTokenExpired = () => updateToken(() => console.log('Token refreshed'));
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = (): string | undefined => _kc.token;

const getTokenParsed = (): Keycloak.KeycloakTokenParsed | undefined => _kc.tokenParsed;

const isLoggedIn = (): boolean => !!_kc.token;

const updateToken = (successCallback: () => void): Promise<void> =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = (): string | undefined => _kc.tokenParsed?.preferred_username;

const getId = ():string | undefined => _kc.tokenParsed?.sub;

const hasRole = (roles: string[]): boolean => roles.some((role) => _kc.hasRealmRole(role));

const hasGroup = (groups: string[]): boolean => groups.some((group) => inGroup(group));

const getGroups = (): Array<string> => 
  (_kc.tokenParsed && _kc.tokenParsed['groups'] as Array<string>) ?? [];

const inGroup = (group: string): boolean => getGroups()
  .map((g) => g.toLowerCase().replace(' ', '').replace('/', ''))
  .includes(group.toLowerCase());

const getOrgs = (): number[] => _kc.tokenParsed?.orgIds ?? [];

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  getId,
  hasRole,
  hasGroup,
  getGroups,
  getOrgs
};

export default KeycloakService;