import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Typography, Button, Box, Container, Stack, TextField, InputAdornment, Link } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import { visuallyHidden } from '@mui/utils';
import { cardSX, pageHeaderSX } from '../utilities/CSS';
import AdminUsersTable from './AdminUsersTable';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AdminAddUserModal from './AdminAddUserModal';
import AdminUploadStudentModal from './AdminUploadStudentModal';
import { userTypeValues } from '../contexts/AdminUsersDataContext';
import { AdminUsersDataContext } from '../contexts/AdminUsersDataContext';

export const BackToUsersLink = () => {
  const navigate = useNavigate();

  return (
    <Link
      color='inherit'
      onClick={() => navigate('/admin/users')}  
      underline='hover'
      sx={{
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        mt: 0.5,
        mb: 1.25
      }}
    >
      <ChevronLeftIcon fontSize='small' sx={{ ml: '-0.25rem', mr: 0.25 }} />
      <Typography variant='body2'>Back to Users</Typography>
    </Link>
  );
};

type AdminSearchUsersProps = {
  autoFocus?: boolean;
  placeholderText: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
};
export function AdminSearchUsers({ autoFocus=true, placeholderText, searchQuery, setSearchQuery }: AdminSearchUsersProps) {
  return(
    <Container sx={{ p: { xs: 2.5, sm: 2.5 } }}>
      <Stack component='form' direction='row' alignItems='center' gap={1.25}>
        {/* Input field needs a label, but we don't want to see it */}
        <Typography component='label' htmlFor='search-institution-users' sx={visuallyHidden}>Search Institution Users</Typography>
        <TextField
          size='small'
          type='search'
          id='search-institution-users'
          placeholder={placeholderText}
          fullWidth
          InputProps={{
            sx: { pl: 1},
            startAdornment: <InputAdornment position='start'><SearchIcon sx={{ color: 'grey.800' }} /></InputAdornment>
          }}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            // Ignore ENTER key presses in the search field - don't want the form to submit and the page to reload
            if (e.key === 'Enter') e.preventDefault();
          }}
          autoFocus={autoFocus}
        />

        <Button
          color='inherit'
          disabled={!searchQuery}
          title='Clear search'
          variant='outlined'
          sx={{
            fontSize: '1rem',
            textTransform: 'none'
          }}
          onClick={() => setSearchQuery('')}
        >Clear</Button>
      </Stack>
    </Container>
  );
}

export default function AdminCenterUsers() {
  const [searchQuery, setSearchQuery] = useState('');
  const [addUsersIsOpen, setAddUsersIsOpen] = useState(false);
  const [uploadStudentIsOpen, setUploadStudentIsOpen] = useState(false);
  const { selectedUserType, setSelectedUserType } = useContext(AdminUsersDataContext);
  
  const handleOpenAddUserModal = () => {
    setAddUsersIsOpen(true);
  }

  const handleOpenUploadStudentsModal = () => {
    setUploadStudentIsOpen(true);
  }

  const fakeTabSX = {
    fontWeight: 'bold',
    cursor: 'pointer',
    padding: '10px',
    marginRight: '16px',
    borderBottom: '4px solid transparent',
    color: 'inherit'
  };

  return (
    <>
      {/* 2024-12-17: FEBS-1195: Commenting out "+ Add User" button for now... */}
      {/* <Button
        onClick={handleOpenAddUserModal}
        sx={{
          fontSize: '1rem',
          color: 'white',
          float: 'right',
          textTransform: 'none',
          bgcolor: 'InceptiaGreen.main',
          '&:hover': {
            backgroundColor: 'InceptiaGreen.dark',
          },
        }}
      >
        + Add User
      </Button> */}
      {/* 2024-12-02: Commenting out "Upload Students" button for now... */}
      {/* <Button
        startIcon={<FileUploadIcon />}
        onClick={handleOpenUploadStudentsModal}
        sx={{
          marginLeft: 1,
          fontSize: '1rem',
          color: 'white',
          float: 'right',
          textTransform: 'none',
          bgcolor: 'InceptiaGreen.main',
          '&:hover': {
            backgroundColor: 'InceptiaGreen.dark',
          },
        }}
      >
        Upload Students
      </Button> */}

      <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4 }}>Users</Typography>
      <AdminAddUserModal addUserIsOpen={addUsersIsOpen} setAddUserIsOpen={setAddUsersIsOpen} />
      <AdminUploadStudentModal uploadStudentIsOpen={uploadStudentIsOpen} setUploadStudentIsOpen={setUploadStudentIsOpen} />

      {/* Buttons for switching between Institution, Students, and Contributors tables */}
      <Box sx={{ display: 'flex', justifyContent: 'left', mb: 2, bgcolor: 'inherit', paddingBottom: '5px', gap: '20px' }}>
        {userTypeValues.map((userType) => (
          <Typography
            key={userType}
            sx={{
              ...fakeTabSX,
              borderColor: selectedUserType === userType ? 'InceptiaGreen.main' : 'transparent',
              color: selectedUserType === userType ? 'InceptiaGreen.main' : 'inherit'
            }}
            onClick={() => setSelectedUserType(userType)}>{userType}</Typography>
        ))}
      </Box>

      <Paper sx={{ ...cardSX.paper, mb: 3.75 }}>
        <AdminSearchUsers
          placeholderText={selectedUserType === 'Institution' ? `Search Institution Users` : `Search ${selectedUserType}`}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </Paper>

      <Paper sx={{ ...cardSX.paper, mb: 3.75 }}>
        <AdminUsersTable searchQuery={searchQuery}/>
      </Paper>
    </>
  );
}