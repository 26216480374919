import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createFilterOptions, Autocomplete, Box, InputAdornment, IconButton, List, ListItemButton, ListItemText, ListSubheader, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpen from '@mui/icons-material/MenuOpen';
import SearchIcon from '@mui/icons-material/Search';
import { visuallyHidden } from '@mui/utils';
import { sideBarDrawerWidth, SideBarDrawerProps } from './SideBarDrawer';
import { inceptiaGreenAlphaColors, listItemSX } from '../utilities/CSS';
import { OrganizationContext, iOrganization } from '../contexts/OrganizationContext';
import { RoleRouteMapper } from './RoleRouteMapper';
import { AdminRoleNodes } from '../utilities/APIInterfaces';
import KeycloakService from '../services/KeycloakService';

type NavRowType = { id: number, name: string, url: string, selectedUrlPrefix: string };
const navRows: NavRowType[] = [
  { id: 0, name: `${process.env.REACT_APP_TITLE}`, url: '/admin/forms', selectedUrlPrefix: '/admin/form' },
  {id: 1, name: 'Notifications', url: '/admin/notifications', selectedUrlPrefix: '/admin/notification'},
  {id: 2, name: 'Users', url: '/admin/users', selectedUrlPrefix: '/admin/user'},
  {id: 3, name: 'Reports', url: '/admin/reports', selectedUrlPrefix: '/admin/report'}
];

type NavListProps = {
  pathname: string;
  handleNavItemClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
  hasUsersAccess: boolean
};
const NavList = ({ pathname, handleNavItemClick, hasUsersAccess }: NavListProps) => {
  return (<>
    {/* Don't show the users nav item if the user doesn't have access */}
    {navRows.filter(row => row.name !== 'Users' || hasUsersAccess).map(row => (
      <ListItemButton
        key={row.id}
        // Want the nav item to remain selected even for sub-pages under its parent
        selected={pathname === row.url || pathname.startsWith(row.selectedUrlPrefix)}
        onClick={(event) => handleNavItemClick(event, row.id)}
        sx={{
          ...(listItemSX),
          borderRadius: '4px',
          py: 2,
          pl: 3
        }}
      >
        <ListItemText
          sx={{ my: 0 }}
          primaryTypographyProps={{fontSize: '14px', fontWeight: 'bold' }}
          primary={row.name} />
      </ListItemButton>
    ))}
  </>);
};

type InstitutionAutocompleteProps = {
  organizations: iOrganization[],
  handleAutoCompleteChange: (e: React.SyntheticEvent<Element, Event>, value: iOrganization | null) => void
};
const InstitutionAutocomplete = ({ organizations, handleAutoCompleteChange }: InstitutionAutocompleteProps) => {
  return (
    <Box component='form' sx={{ mb: 3}}>
      {/* Input field needs a label, but we don't want to see it */}
      <Typography component='label' htmlFor='search-institutions' sx={visuallyHidden}>Search institutions</Typography>
      <Autocomplete
        id='search-institutions'
        autoHighlight={true}
        clearOnEscape
        forcePopupIcon={false}
        fullWidth
        options={organizations}
        noOptionsText='No institutions found'
        size='small'
        onChange={handleAutoCompleteChange}

        // Let user search for all values, but only want to see the name in the input
        getOptionLabel={option => option.name || ''}
        filterOptions={createFilterOptions({
          matchFrom: 'any',
          stringify: (option) => option.name + option.opeid + option.city + option.state
        })}

        // Customize the HTML for each item in the list
        renderOption={(props, option) => (
          // key prop needs to be last so as to NOT be overwritten by the key provided by MUI
          // https://stackoverflow.com/questions/69395945/how-can-i-add-unique-keys-to-react-material-ui-autocomplete-component
          // Warning: Encountered two children with the same key, `SUNY EMPIRE STATE COLLEGE`. Keys should be unique...
          <Box component='li' sx={{ display: 'flex', flexWrap: 'wrap', lineHeight: 1.3 }} {...props} key={option.id}>
            <Box component='span' sx={{ fontWeight: 700, width: '100%' }}>{option.name}</Box>
            <Box component='span' sx={{ fontSize: '0.875rem', mr: 1 }}>{option.opeid}</Box>
            {option.city && <Box component='span' sx={{ fontSize: '0.875rem' }}>{option.city}</Box>}
            {option.state && <Box component='span' sx={{ fontSize: '0.875rem' }}>, {option.state}</Box>}
          </Box>
        )}

        renderInput={(params) =>
          <TextField {...params}
            InputProps={{
              ...params.InputProps,
              placeholder: 'Institution',
              startAdornment: <InputAdornment position='start' sx={{ width: '1rem' }}><SearchIcon sx={{ color: 'grey.800' }} /></InputAdornment>,

              // Want the '...' overflow effect when the text is too long (note: won't see it while input has focus)
              sx: { textOverflow: 'ellipsis', overflow: 'hidden' }
            }}
          />
        }

        // Style the selected items in the dropdown list
        slotProps={{
          popper: {
            sx: {
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                backgroundColor: inceptiaGreenAlphaColors.hover
              },
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: inceptiaGreenAlphaColors.selected
              },
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                backgroundColor: inceptiaGreenAlphaColors.selectedHover
              }
            }
          }
        }}
      />
    </Box>
  );
};

export default function AdminLeftNav({ widthUnderMd, setOpenSideBarDrawer }: SideBarDrawerProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { organization, setOrganization, organizations } = useContext(OrganizationContext);
  const [hasUsersAccess, setHasUsersAccess] = useState(false);

  useEffect(() => {
    if (['Admin', 'Manager', 'Operations', 'SrOperations'].some(g => KeycloakService.hasGroup([g]))) {
      setHasUsersAccess(true);
    }
  }, []);   

  const handleAutoCompleteChange = (e: React.SyntheticEvent<Element, Event>, value: iOrganization | null) => {
    // If user cleared the input, leave the existing organization intact
    value && setOrganization(value);
  };

  const handleNavItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    let selected_row = navRows.find((row) => {return row.id === index});

    if (selected_row) {
      navigate(selected_row.url);
    } else {
      navigate("/admin/forms");
    }
    if (widthUnderMd) setOpenSideBarDrawer(false);
  };

  return (
    <Box position='fixed' sx={{
      width: sideBarDrawerWidth,
      bgcolor: 'background.paper',
      height: '100%',
      overflowY: 'auto',
      px: 1.5
    }}>
      {/* Inceptia logo and close button */}
      <Stack direction='row' alignItems='center' sx={{ mb: 4, py: 1.5 }}>
        <Box component='img' src={process.env.PUBLIC_URL + '/logo_inceptia.png'} alt='Inceptia logo' />

        <IconButton
          size="large"
          aria-label="sidebar close button"
          onClick={() => setOpenSideBarDrawer(false)}
          color="default"
          title='Close sidebar'
          sx={{
            ml: 'auto',
            '&:hover': {
              backgroundColor: inceptiaGreenAlphaColors.hover
            }
          }}
        >
          {widthUnderMd ? <CloseIcon /> : <MenuOpen />}
        </IconButton>
      </Stack>

      <RoleRouteMapper 
        roles={Object.keys(new AdminRoleNodes())
          .filter((role) => !['institutionAdmin','institution'].includes(role))}
        >
          <InstitutionAutocomplete organizations={organizations || []} handleAutoCompleteChange={handleAutoCompleteChange} />
      </RoleRouteMapper>

      {/* Selected organization logo, name, and opeid */}
      <Box
        component='img'
        src={`${process.env.PUBLIC_URL}/logos/${organization?.opeid}logo.png`}
        alt={`${organization?.name} Logo`}

        // Hacky, but it works
        onLoad={e => (e.target as HTMLImageElement).classList.remove('logo-file-not-found')}
        onError={e => (e.target as HTMLImageElement).classList.add('logo-file-not-found')}
        sx={{ '&.logo-file-not-found': { display: 'none' } }}
      />
      <Typography variant='body1' sx={{ fontWeight: 500, mb: 3 }}>{organization?.name}</Typography>

      {/* Admin navigation list */}
      <List component="nav" aria-label="main admin navigation">

        <ListSubheader>INSTITUTION SERVICES</ListSubheader>

        <NavList pathname={pathname} handleNavItemClick={handleNavItemClick} hasUsersAccess={hasUsersAccess} />
      </List>
    </Box>
  );
}