import { AdminUsersDataContextProvider } from '../contexts/AdminUsersDataContext';
import AdminCenterUsers from '../components/AdminCenterUsers';

export default function AdminUsers() {
  return (
    <AdminUsersDataContextProvider>
      <AdminCenterUsers />
    </AdminUsersDataContextProvider>
  );
}
