import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography, TableCell, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as yup from 'yup';
import notificationService from '../../services/NotificationService';
import { ApplicationTypeEnum, NotificationTemplateStatusEnum, NotificationCommunicationTypeEnum, NotificationTemplateDto } from '../../apis/notifications';
import ManageCrudPage, { DropdownStyles } from './ManageCrudPage';
import TemplateLinkForm from './TemplateLinkForm';
import { NotificationEmailSender } from '../../apis/notifications/models/notification-email-sender';

interface NotificationTemplateCrudProps {
    orgId: number | undefined;
    isInceptiaUser: boolean;
    isInceptiaOrganization: boolean;
}

const NotificationTemplateCrud = ({ orgId = 0, isInceptiaUser = false, isInceptiaOrganization = false }: NotificationTemplateCrudProps) => {
    const [templateToLink, setTemplateToLink] = useState<NotificationTemplateDto | undefined>(undefined);
    const [isLinkDialogOpen, setIsLinkDialogOpen] = useState<boolean>(false);
    const [emailSenders, setEmailSenders] = useState<NotificationEmailSender[]>([]);

    useEffect(() => {
        let getSenders = async () => {
            let senders = await notificationService.GetAllEmailSenders();
            setEmailSenders(senders);
        };
        getSenders();
    }, []);

    const initialValues: NotificationTemplateDto = {
        name: '',
        communication_type: NotificationCommunicationTypeEnum.Email,
        application_type: ApplicationTypeEnum.FormsApp,
        org_id: orgId,
        status: NotificationTemplateStatusEnum.Approved,
        email_sender_id: undefined
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        communication_type: yup.string().oneOf(Object.values(NotificationCommunicationTypeEnum)).required(),
        subject: yup.string().notRequired(),
        body: yup.string().required(),
        email_sender_id: yup.number().when("communication_type", (communication_type, schema) => {
            if (communication_type.length === 1 && 
                communication_type[0] === NotificationCommunicationTypeEnum.Email.toString())
                return schema.required("Email From is required");
            else 
                return schema.notRequired();
        })
    });

    const renderFormFields = (formik: any, isCreate: boolean) => {
        let readOnly: boolean = !isInceptiaUser || (!isCreate && formik.values.org_id !== orgId);

        return (<>
            <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 6.25 }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="name"
                        label="Name"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputProps={{
                            readOnly: readOnly
                        }}
                        InputProps={{
                            className: readOnly ? "Mui-disabled" : undefined
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={formik.touched.communication_type && Boolean(formik.errors.communication_type)} sx={DropdownStyles}>
                        <InputLabel id="communication-type-label">Communication Type</InputLabel>
                        <Select
                            labelId="communication-type-label"
                            id="communication_type"
                            name="communication_type"
                            label="Communication Type"
                            value={formik.values.communication_type}
                            onChange={formik.handleChange}
                            disabled={readOnly ? true : undefined}
                        >
                            {Object.values(NotificationCommunicationTypeEnum).map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.communication_type && formik.errors.communication_type ? (
                            <Typography variant="caption" color="error">{String(formik.errors.communication_type)}</Typography>
                        ) : null}
                    </FormControl>
                </Grid>

                {formik.values.communication_type === NotificationCommunicationTypeEnum.Email && (
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth error={formik.touched.email_sender_id && Boolean(formik.errors.email_sender_id)} sx={DropdownStyles}>
                            <InputLabel id="email-sender-label">Email From</InputLabel>
                            <Select
                                labelId="email-sender-label"
                                id="email_sender_id"
                                name="email_sender_id"
                                label="Email From"
                                value={formik.values.email_sender_id}
                                onChange={formik.handleChange}
                                disabled={readOnly ? true : undefined}
                            >
                                {emailSenders && emailSenders.map((sender) => (
                                    <MenuItem key={sender.id} value={sender.id}>
                                        {sender.address}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.email_sender_id && formik.errors.email_sender_id ? (
                                <Typography variant="caption" color="error">{String(formik.errors.email_sender_id)}</Typography>
                            ) : null}
                        </FormControl>
                    </Grid>
                )}

                {formik.values.communication_type === NotificationCommunicationTypeEnum.Email && (
                    <Grid item xs={12}>
                        <TextField
                            id="subject"
                            label="Subject"
                            fullWidth
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                            helperText={formik.touched.subject && formik.errors.subject}
                            inputProps={{
                                readOnly: readOnly
                            }}
                            InputProps={{
                                className: readOnly ? "Mui-disabled" : undefined
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextField
                        id="body"
                        label="Body"
                        fullWidth
                        multiline
                        rows={4}
                        value={formik.values.body}
                        onChange={formik.handleChange}
                        error={formik.touched.body && Boolean(formik.errors.body)}
                        helperText={formik.touched.body && formik.errors.body}
                        inputProps={{
                            readOnly: readOnly
                        }}
                        InputProps={{
                            className: readOnly ? "Mui-disabled" : undefined
                        }}
                    />
                </Grid>
            </Grid>
        </>);
    };

    const renderTableHeaders = () => (
        <>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Communication Type</TableCell>
        </>
    );

    const renderTableColumns = (item: NotificationTemplateDto) => (
        <>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.status}</TableCell>
            <TableCell>{(item.org_id === orgId ? "" : "Default ") + item.communication_type}</TableCell>
        </>
    );

    const fetchItems = useCallback(async () => {
        if(!isLinkDialogOpen)
        {
            let templates = await notificationService.GetTemplatesByOrgId(orgId);
            if(templates)
                templates = templates.sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
            return templates;    
        }
        return [];
    }, [orgId, isLinkDialogOpen]);

    return (
        <>
            <ManageCrudPage<NotificationTemplateDto>
                title="Manage Notification Templates"
                entityName="Template"
                initialValues={initialValues}
                validationSchema={validationSchema}
                isInceptiaUser={isInceptiaUser}
                fetchItems={fetchItems}
                isDefault={template => template.org_id !== orgId}
                createItem={(template: NotificationTemplateDto) =>
                    notificationService.CreateTemplate({ ...template, org_id: orgId }).then(() => { })
                }
                updateItem={(id: number, template: NotificationTemplateDto) =>
                    notificationService.UpdateTemplate(id, template).then(() => { })
                }
                deleteItem={notificationService.DeleteTemplate}
                renderFormFields={renderFormFields}
                renderTableColumns={renderTableColumns}
                renderTableHeaders={renderTableHeaders}
                customButtons={isInceptiaUser && isInceptiaOrganization ? [{
                    text: "Link",
                    action: template => {
                        setTemplateToLink(template);
                        setIsLinkDialogOpen(true);
                    }
                }] : []}
            />
            <TemplateLinkForm 
                isOpenDialog={isLinkDialogOpen}
                setIsOpenDialog={setIsLinkDialogOpen}
                template={templateToLink}
            />
        </>
    );
};

export default NotificationTemplateCrud;