import DashboardAPI from '../apis/DashboardAPI';
import { iBinder, iBinderRequest } from '../utilities/APIInterfaces';

class ReportService {
  async BinderRequest(binderRequest: iBinderRequest, loader: (v: boolean) => void) {
    loader(true);
    let response = await DashboardAPI.call({
      method: 'POST',
      route: 'Binder', 
      body: binderRequest
    });
    loader(false);
    return response;
  }

  async GetRequestedBinders(opeId: string, loader: (v: boolean) => void): Promise<iBinder[]> {
    let binderData: iBinder[] = [];
    loader(true);
    binderData = await DashboardAPI.callForData<iBinder[]>({
      method: 'GET',
      route: `Binder/org/${opeId}`
    });
    loader(false);
    return binderData;
  }
}

const reportService = new ReportService();
export default reportService;