import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Grid, TextField, MenuItem, Select, FormControl, InputLabel, Container, Autocomplete, AutocompleteChangeReason } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NotificationData, CommunicationTypeEnum } from '../../apis/notifications';
import notificationService from '../../services/NotificationService';
import { useSnackbar } from 'notistack';
import { NotificationTemplateDto } from '../../apis/notifications'; // Assuming the NotificationTemplateDto is imported correctly
import { useDebounce } from "../../utilities/useDebounce";
import ConfirmationDialog from '../ConfirmationDialog';
import { DropdownStyles } from './ManageCrudPage';
import { iOrganization, OrganizationContext } from '../../contexts/OrganizationContext';

const initialValues = {
    communication_type: CommunicationTypeEnum.Email,
    notification_template_id: null,
    notification_schedule_id: null,
    subject: '',
    body: '',
};

const validationSchema = yup.object().shape({
    communication_type: yup.string().required('Communication type is required'),
    notification_template_id: yup.number().nullable(),
    notification_schedule_id: yup.number().nullable(),
    subject: yup.string().nullable(),
    body: yup.string().required('Body is required'),
    template_variables: yup.object().shape({}).nullable(),
});

type NotificationFormUser = {
    auth_id?: string;
    submission_form_id?: number;
    email_address?: string | null;
    phone_number?: string | null;
    organization: iOrganization | null | undefined;
}

type NotificationFormProps = {
    users: NotificationFormUser[];
    isOpenDialog: boolean;
    setIsOpenDialog: Dispatch<SetStateAction<boolean>>;
}

const NotificationForm = ({ users, isOpenDialog, setIsOpenDialog }: NotificationFormProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [templates, setTemplates] = useState<NotificationTemplateDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const { organization } = useContext(OrganizationContext); 

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const notificationData: NotificationData[] = users.map(user => ({
                communication_type: values.communication_type as CommunicationTypeEnum,
                notification_template_id: values.notification_template_id ?? undefined,
                notification_schedule_id: values.notification_schedule_id ?? undefined,
                email_address: user.email_address ?? undefined,
                phone_number: user.phone_number ?? undefined,
                subject: values.subject || null,
                body: values.body,
                template_variables: {},
                auth_id: user.auth_id,
                submission_form_id: user.submission_form_id,
                ope_id: user.organization?.opeid ?? undefined
            }));

            try {
                const response = await notificationService.SendNotification(notificationData);
                if (response?.successful_notifications?.length > 0) {
                    enqueueSnackbar('Notification sent successfully!', { variant: "success" });
                    resetForm({ values: initialValues });
                } else {
                    throw new Error('Notification not sent');
                }
            } catch (error) {
                enqueueSnackbar('Error sending notification!', { variant: "error" });
            }
        },
    });

    const handleSearchTemplates = useCallback(async (inputValue: string | null = null, communication_type: string | null = null) => {
        if (loading) {
            return;
        }

        try {
            let templateResults: NotificationTemplateDto[] = [];

            if (organization?.id) {
                let searchTemplates = await notificationService.GetTemplatesByOrgId(organization?.id);
                templateResults = [...templateResults, ...searchTemplates];
            }

            if (formik.values.communication_type) {
                templateResults = templateResults.filter(x => 
                    x.communication_type === (communication_type ?? formik.values.communication_type as string));
            }
            
            setTemplates(templateResults);
        } catch (error) {
            console.error('Error fetching templates', error);
            enqueueSnackbar('Error fetching templates!', { variant: 'error' });
        }
    }, [enqueueSnackbar, organization?.id, formik?.values?.communication_type, loading]);

    useEffect(() => {
        handleSearchTemplates();
    }, [handleSearchTemplates]);

    useEffect(() => {
        if(isSending) {
            formik.handleSubmit();
            setIsSending(false);    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSending]); //not including formik to avoid constant submissions

    const debounce = useDebounce(async (ev: any) => {
        console.log('debounce -------------------------- ');
        setLoading(true);
        await handleSearchTemplates(ev.target.value);
        setLoading(false);
    }, 500);

    const handleTemplateChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: NotificationTemplateDto | null,
        reason: AutocompleteChangeReason
    ) => {
        formik.setFieldValue('notification_template_id', newValue?.id || null);
        if (newValue?.subject) {
            formik.setFieldValue('subject', newValue?.subject);
        }
        if (newValue?.body) {
            formik.setFieldValue('body', newValue?.body);
        }
    };
    return (
        <ConfirmationDialog
            open={isOpenDialog}
            setOpenDialog={setIsOpenDialog}
            ariaPrefix={'notifications'}
            dialogTitle={'Send Notifications'}
            singleButton={false}
            ctaButtonText={'Send Notification'}
            ctaButtonWorkingText={'Sending Notification'}
            setIsWorking={setIsSending}
            isWorking={isSending}
            width={1000}
            maxWidth={1000}>

            <Container sx={{ p: { xs: 2.25, sm: 4.5 } }}>
                <form id="notificationForm" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth sx={DropdownStyles}>
                                <InputLabel id="communication-type-label">Communication Type</InputLabel>
                                <Select
                                    labelId="communication-type-label"
                                    id="communication_type"
                                    name="communication_type"
                                    value={formik.values.communication_type}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        formik.setFieldValue(name, value);
                                        formik.setFieldValue('notification_template_id', null);
                                        handleSearchTemplates(null, value);
                                    }}
                                    error={formik.touched.communication_type && Boolean(formik.errors.communication_type)}
                                >
                                    <MenuItem value={CommunicationTypeEnum.Email}>Email</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                id="notification_template_id"
                                options={templates}
                                getOptionLabel={(option) => option.name || ''}
                                loading={loading}
                                value={templates.find(template => template.id === formik.values.notification_template_id) || null}
                                onChange={handleTemplateChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Template"
                                        error={formik.touched.notification_template_id && Boolean(formik.errors.notification_template_id)}
                                        helperText={formik.touched.notification_template_id && formik.errors.notification_template_id}
                                        onChange={debounce}
                                    />
                                )}
                            />
                        </Grid>

                        {formik.values.communication_type === CommunicationTypeEnum.Email && (
                            <Grid item xs={12}>
                                <TextField
                                    id="subject"
                                    label="Subject"
                                    fullWidth
                                    value={formik.values.subject}
                                    onChange={formik.handleChange}
                                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                                    helperText="Optional: Overrides the subject in the template"
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                id="body"
                                name="body"
                                label="Body"
                                value={formik.values.body}
                                onChange={formik.handleChange}
                                error={formik.touched.body && Boolean(formik.errors.body)}
                                helperText={formik.touched.body && formik.errors.body}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </ConfirmationDialog>
    );
};

export default NotificationForm;
