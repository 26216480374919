import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from '@mui/icons-material/Search';
import { NotificationTemplateDto } from "../../apis/notifications";
import notificationService from "../../services/NotificationService";
import { iOrganization, OrganizationContext } from "../../contexts/OrganizationContext";
import { Container, Grid, InputAdornment, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { PageLoader } from "../PageLoader";
import { inceptiaGreenAlphaColors } from "../../utilities/CSS";

type TemplateLinkFormProps = {
    template: NotificationTemplateDto | undefined;
    isOpenDialog: boolean;
    setIsOpenDialog: Dispatch<SetStateAction<boolean>>;
}

const TemplateLinkForm = ({ template, isOpenDialog, setIsOpenDialog }: TemplateLinkFormProps) => {
    const [isLinking, setIsLinking] = useState<boolean>(false);
    const [linkToAllOrganizations, setLinkToAllOrganizations] = useState<boolean>(false);
    const [linkedOrgIds, setLinkedOrgIds] = useState<Set<number>>(new Set<number>([]));
    const { organizations } = useContext(OrganizationContext);
    const [organizationFilter, setOrganizationFilter] = useState<string | undefined>(undefined);
    const [filteredOrganizations, setFilteredOrganizations] = useState<iOrganization[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if(isOpenDialog)
            setLinkToAllOrganizations(template?.is_global ?? false);
        else
            setOrganizationFilter(undefined);
    }, [template?.is_global, isOpenDialog])

    useEffect(() => {
        let getOrgIds = async () => {
            if (isOpenDialog && template?.id) {
                setIsLoading(true);
                let orgIds: number[] = await notificationService.GetOrgIdsForTemplate(template.id);
                setLinkedOrgIds(new Set(orgIds ?? []));
                setIsLoading(false);
            }
        }
        getOrgIds();
    }, [template?.id, isOpenDialog]);

    useEffect(() => {
        let orgs = organizations ?? [];
        orgs = orgs.filter(org => org.opeid !== "INCEPTIA");

        if(!organizationFilter)
            setFilteredOrganizations(orgs);

        let result = orgs?.filter(org => org.name?.toLowerCase()
            ?.includes(organizationFilter?.toLowerCase() ?? "")) ?? [];
        setFilteredOrganizations(result);
    }, [organizations, organizationFilter]);

    useEffect(() => {
        let link = async () => {
            if (template?.id && isLinking)
            {
                await notificationService.UpdateTemplate(template.id, { ...template, is_global: linkToAllOrganizations })
                let orgIds = linkToAllOrganizations ? [] : Array.from(linkedOrgIds ?? []);
                let success = await notificationService.UpdateOrgIdsForTemplate(template.id, orgIds);
                if (success)
                    enqueueSnackbar('Template linked successfully!', { variant: "success" });
                else
                    enqueueSnackbar('Error linking template!', { variant: "error" });
            }
            setIsLinking(false);
            setIsOpenDialog(false);
        }
        link();
        // we only want to trigger this method when 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLinking]);

    let checkboxStyle = {
        '&.Mui-checked .MuiSvgIcon-root': {
            color: "InceptiaGreen.main"
        },
        '&.Mui-checked.Mui-disabled .MuiSvgIcon-root': {
            color: inceptiaGreenAlphaColors.selectedHover
        }
    };

    return <ConfirmationDialog
        open={isOpenDialog}
        setOpenDialog={setIsOpenDialog}
        ariaPrefix={'notifications'}
        dialogTitle={'Link Template - ' + template?.name}
        singleButton={false}
        ctaButtonText={'Link'}
        ctaButtonWorkingText={'Linking'}
        setIsWorking={setIsLinking}
        isWorking={isLinking}
        width={800}
        maxWidth={800}>
        <Container>
            <Grid container columns={3} pb={3}>
                <Grid item xs={1}>
                    <Checkbox
                        sx={checkboxStyle}
                        onChange={(e) => setLinkToAllOrganizations(e.target.checked)}
                        checked={linkToAllOrganizations}
                        title='Link to All Organizations'
                        id='allOrganizations'
                    />
                    <label htmlFor='allOrganizations'>Link to All Organizations</label>
                </Grid>
                {filteredOrganizations && <Grid item xs={2}>
                    <TextField
                        type='search'
                        id='filter-organizations'
                        placeholder='Filter organizations'
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                        }}
                        value={organizationFilter}
                        onChange={e => setOrganizationFilter(e.target.value)}
                        autoFocus
                        size='small'
                    />
                </Grid>}
            </Grid>
            {filteredOrganizations && <Container>
                <Grid container columns={2}>
                    {isLoading && <PageLoader message='loading' />}
                    {!isLoading && filteredOrganizations.map(org => <Grid item key={`linkedOrg${org?.id}`} xs={1}>
                        <Checkbox
                            sx={checkboxStyle}
                            title={org?.name ?? undefined}
                            id={`link-org-${org?.id}`}
                            disabled={linkToAllOrganizations}
                            checked={linkToAllOrganizations || linkedOrgIds.has(org?.id)}
                            onChange={e => {
                                setLinkedOrgIds(orgIds => {
                                    if (e.target.checked) {
                                        orgIds.add(org?.id);
                                    } else {
                                        orgIds.delete(org?.id);
                                    }
                                    return new Set(orgIds);
                                });
                            }}
                        />
                        <label style={{ color: linkToAllOrganizations ? "gray" : "unset" }} htmlFor={`link-org-${org?.id}`}>{org?.name ?? undefined}</label>
                    </Grid>)}
                </Grid>
            </Container>}
        </Container>
    </ConfirmationDialog>
};

export default TemplateLinkForm;
