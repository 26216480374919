import DashboardAPI from "../apis/DashboardAPI";
import { enqueueSnackbar } from "notistack";

class OrgService {

    async CheckForValidPURL (purl: string | undefined, loader: (v: boolean) => void) : Promise<boolean> {
        loader(true);
        let isValid = await DashboardAPI.callForData<boolean>({
            method: "GET",
            route: `${this.GetAPIPrefix()}/CheckValidPurl/${purl}`
        });
        loader(false);
        return isValid;
    }

    async GetSSNRequired (purl: string | undefined, loader: (v: boolean) => void) : Promise<string> {
      loader(true);
      let result = await DashboardAPI.callForData({
          method: "GET",
          route: `${this.GetAPIPrefix()}/GetOrgByPurl/${purl}`
      }) as {ssN_Required: string};
      loader(false);
      return result?.ssN_Required || "REQUIRED";
    }

  //returns the orgs based on the user's token
    async GetAllowedOrganizations( loader: (v: boolean) => void) {

    loader(true);
    let result = await DashboardAPI.callForData({
      method: "GET",
      route: `${this.GetAPIPrefix()}`
    });
    loader(false); 

    return result;
  }

  async GetProductOrgLinkList(orgId: number,  loader: (v: boolean) => void) {
      loader(true);
      let products = await DashboardAPI.callForData({
          method: "GET",
          route: `Product/GetProductsByOrg/${orgId}`
      });
      loader(false);
      return products;
  }

  async GetInstitutionUsers(opeId: string | undefined, loader: (v: boolean) => void) {
    let result = null;

    if (opeId != null) {
        loader(true);
        result = await DashboardAPI.callForData({
            method: "GET",
            route: `${this.GetAPIPrefix()}/${opeId}/InstitutionUsers`
        });
        loader(false);
    }
    return result;
  }

  async GetStudentUsers(opeId: string | undefined, loader: (v: boolean) => void) {
    let result = null;

    if (opeId != null) {
        loader(true);
        result = await DashboardAPI.callForData({
            method: "GET",
            route: `${this.GetAPIPrefix()}/${opeId}/Users?type=0`
        });
        loader(false);
    }
    return result;
  }

    GetAPIPrefix(): string {
        let apiPrefix: string = 'Org';
        return apiPrefix;
    }
}

const orgService = new OrgService()
export default orgService;
