import { useContext, useEffect, useState } from 'react';
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils';
import TableRowsLoader, { type TableLoaderProps } from './TableRowsLoader';
import { OrganizationContext } from '../contexts/OrganizationContext';
import reportService from '../services/ReportService';
import documentService from '../services/DocumentService';
import { iBinder } from '../utilities/APIInterfaces';
import { BinderStatusLabel } from '../utilities/FormEnums';
import { formatDate } from '../utilities/HelperFunctions';
import { enqueueSnackbar } from 'notistack';

const numberOfDaysBindersAreKept = 10;

const tdSX = {
  py: 1.125
};

const TableLoader = ({ colsNum, rowsNum, animation = 'pulse' }: TableLoaderProps) => {
  return (
    <TableWrapper>
      <TableRowsLoader colsNum={colsNum} rowsNum={rowsNum} animation={animation} rowHeight={55} />
    </TableWrapper>
  );
};

const TableWrapper = ({ children }: { children: React.ReactNode }) => {
  const thSX = {
    color: '#1e1e1e',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  };

  return (
    <TableContainer>
      <Table aria-label='Binders report table' size='small'>
        <Box component={'caption'} sx={visuallyHidden}>Binders report table</Box>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={thSX}>Date Requested</TableCell>
            <TableCell component="th" sx={thSX}>Requested By</TableCell>
            <TableCell component="th" sx={thSX}>Status</TableCell>
            <TableCell component="th" sx={thSX}>Deletion Date*</TableCell>
            <TableCell component="th" sx={thSX}>Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
      <Typography
        variant='body1'
        sx={{ fontSize: '0.75rem', color: 'grey.600', mt: 2, fontWeight: 500 }}
      >* Binders are retained for {numberOfDaysBindersAreKept} days before being deleted.</Typography>
    </TableContainer>
  );
};

const TableEmpty = ({ colspan }: { colspan: number }) => {
  return (
    <TableWrapper>
      <TableRow>
        <TableCell colSpan={colspan} sx={{ borderBottom: 'none' }}>
          <Typography
            variant='body1'
            sx={{
              // WAVE accessibility contrast error if color matches Figma color of #b8b8b8, so darken it enough to pass (grey.600 = #757575)
              color: 'grey.600',
              fontWeight: 500,
              py: 3.75,
              textAlign: 'center'
            }}
          >No binders found...</Typography>
        </TableCell>
      </TableRow>
    </TableWrapper>
  );
};

const ReportBinders = () => {
  const { organization } = useContext(OrganizationContext);
  const [isBinderDataLoaded, setIsBinderDataLoaded] = useState(false);
  const [binderData, setBinderData] = useState<iBinder[]>([]);

  useEffect(() => {
    // Want to get a fresh set of binders whenever the organization changes
    setIsBinderDataLoaded(false);
  }, [organization]);

  useEffect(() => {
    const GetBindersAsync = async () => {
      if (organization?.opeid) {
        await reportService.GetRequestedBinders(organization.opeid, (v) => v).then(result => {
          setBinderData(result);
        }).catch(error => {
          console.log('ExportBindersAsync 1', error);
        }).finally(() => {
          setIsBinderDataLoaded(true);
        });
      }
    };
  
    if (!isBinderDataLoaded && organization?.opeid) GetBindersAsync();
  }, [isBinderDataLoaded, organization?.opeid]);

  const handleDownloadFile = async (docUuid: string, filename: string) => {
    const blob = await documentService.GetFile(docUuid, (v) => v);
    if (blob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      enqueueSnackbar('Failed to retrieve file', { variant: "error" });
    }
  };

  return (<>
    <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 2 }}>Binders</Typography>

    {!isBinderDataLoaded && <TableLoader colsNum={5} rowsNum={binderData.length || 5} /> }

    {isBinderDataLoaded && binderData.length === 0 && <TableEmpty colspan={5} /> }

    {isBinderDataLoaded && binderData.length > 0 && (
      <TableWrapper>
        {binderData.map(row => {
          let expireDate = '';
          if (row.binderStatusId === 2) {
            const dt = new Date(row.statusDate);
            expireDate = new Date(dt.setDate(dt.getDate() + numberOfDaysBindersAreKept)).toString();
          }
          return (
            <TableRow key={row.id}>
              <TableCell sx={tdSX}>{row.createdOn}</TableCell>
              <TableCell sx={tdSX}>{row.requestedBy}</TableCell>
              <TableCell sx={tdSX}>{BinderStatusLabel.get(row.binderStatusId)}</TableCell>
              <TableCell sx={tdSX}>{formatDate(expireDate)}</TableCell>
              <TableCell sx={{...tdSX, color: 'InceptiaGreen.main', fontWeight: '500'}}>
                {row.fileName && (
                  <Link
                    color='inherit'
                    sx={{ cursor: 'pointer', wordBreak: 'break-all' }}
                    onClick={() => handleDownloadFile(row.documentUuid, row.fileName || '')} 
                  >{row.fileName}</Link>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableWrapper>
    )}
  </>);
};

export default ReportBinders;
