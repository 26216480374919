import DashboardAPI from '../apis/DashboardAPI';
import KeycloakService from "./KeycloakService";
import { enqueueSnackbar } from 'notistack';

class DocumentService {
  async GetFile(uuid: string, loader: (v: boolean) => void) {
    let blob = null;
    loader(true);
    try {
      blob = await DashboardAPI.callForFile({
        method: 'GET',
        route: `Document/${uuid}`
      });
    } catch (error: any) {
      if (error.status === 404) {
        enqueueSnackbar('File could not be found', { variant: "error" });
      }
    } finally {
      loader(false);
    }
    return blob;
  }
    async UploadFormFiles(formData: FormData, authId: string | undefined, loader: (v: boolean) => void) {
        let response = null;
        loader(true);
        response = await fetch(`${process.env.REACT_APP_DASHBOARD_API_URL}/${this.GetAPIPrefix()}/User/${authId}`, 
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${ KeycloakService.getToken() }`
                },
                body: formData
            });
        loader(false);
        return response;
    }

    async DeleteFormFile(authId: string | undefined, fileURL: string,  loader: (v: boolean) => void) {
        let response = null;
        loader(true);
        response = await fetch(`${fileURL}/User/${authId}`, {
            method: "DELETE",
        })
        loader(false);
        return response;
    }

    GetAPIPrefix(): string {
        let apiPrefix: string = 'Document';
        return apiPrefix;
    }
}

const documentService = new DocumentService()
export default documentService;