import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { OrganizationContext } from '../contexts/OrganizationContext';
import orgService from '../services/OrgService';
import { useParams } from 'react-router-dom';

export type UserType = 'Institution' | 'Students' | 'Contributors';
export const userTypeValues = ['Institution', 'Students', 'Contributors'] as const;

export interface iInstitutionUserData {
    [index: string]: string | string[] | number | undefined;
    id: number;
    authId: string;
    firstName: string;
    lastName: string;
    email: string;
    productIds: string[];
    role: string;
    statusId: number;
    status: string; // Calculated property
}
export interface iStudentUserData {
    [index: string]: string | number | undefined;
    id: number;
    authId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    studentId: string;
    statusId: number;
    status: string; // Calculated property
}
export interface iContributorUserData {
    [index: string]: string | number | undefined;
    id: number;
    authId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    userLinkStatusId: number;
}

type AdminUsersDataContextType = {
    institutionUser: iInstitutionUserData | null | undefined;
    setInstitutionUser: (User: iInstitutionUserData | null) => void;
    institutionUsers: iInstitutionUserData[] | null | undefined;
    setInstitutionUsers: (Users: iInstitutionUserData[] | null) => void;
    isInstitutionUsersDataLoaded: boolean;
    setIsInstitutionUsersDataLoaded: (value: boolean) => void;

    studentUser: iStudentUserData | null | undefined;
    setStudentUser: (User: iStudentUserData | null) => void;
    studentUsers: iStudentUserData[] | null | undefined;
    setStudentUsers: (Users: iStudentUserData[] | null) => void;
    isStudentUsersDataLoaded: boolean;
    setIsStudentUsersDataLoaded: (value: boolean) => void;

    selectedUserType: UserType;
    setSelectedUserType: (value: UserType) => void;
}

export const AdminUsersDataContext = createContext<AdminUsersDataContextType>({
    institutionUser: null,
    setInstitutionUser: institutionUser => console.warn('no user'),
    institutionUsers: null,
    setInstitutionUsers: institutionUsers => console.warn('no user'),
    isInstitutionUsersDataLoaded: false,
    setIsInstitutionUsersDataLoaded: value => console.warn('no user'),
    
    studentUser: null,
    setStudentUser: studentUser => console.warn('no user'),
    studentUsers: null,
    setStudentUsers: studentUsers => console.warn('no user'),
    isStudentUsersDataLoaded: false,
    setIsStudentUsersDataLoaded: value => console.warn('no user'),

    selectedUserType: 'Institution',
    setSelectedUserType: value => console.warn('no user'),
  });

export const useUsersData = () => useContext(AdminUsersDataContext);

interface UsersContextProps {
    children: React.ReactNode;
}

export const AdminUsersDataContextProvider: React.FC<UsersContextProps> = ({ children }) => {
  const [institutionUser, setInstitutionUser] = useState<iInstitutionUserData | null>(null);
  const [institutionUsers, setInstitutionUsers] = useState<iInstitutionUserData[] | null>([]);
  const [isInstitutionUsersDataLoaded, setIsInstitutionUsersDataLoaded] = useState(false);

  const [studentUser, setStudentUser] = useState<iStudentUserData | null>(null);
  const [studentUsers, setStudentUsers] = useState<iStudentUserData[] | null>([]);
  const [isStudentUsersDataLoaded, setIsStudentUsersDataLoaded] = useState(false);

  const [selectedUserType, setSelectedUserType] = useState<UserType>('Institution');
  const { enqueueSnackbar } = useSnackbar();
  const { organization } = useContext(OrganizationContext);
  const { authId } = useParams();

  useEffect(() => {
    // Want to reset all the "is data loaded" flags when the organization changes
    setIsInstitutionUsersDataLoaded(false);
    setIsStudentUsersDataLoaded(false);
  }, [organization]);

  useEffect(() => {
    async function GetInstitutionUsers(opeId: string | undefined) {
      await orgService.GetInstitutionUsers(opeId, (v) => v).then(result => {
        if (result) {
          const institutionUsersResult = (result as iInstitutionUserData[]).map(user => ({
              ...user,
              // status: 'Active' // FUTURE: Remove hardcoded value when status is available in the API
              status: user.statusId === 1 ? 'Active' : 'Inactive'

          }));

          if (authId) {
            const user = institutionUsersResult.find(user => user.authId === authId);
            if (user) setInstitutionUser(user);
          }
          setInstitutionUsers(institutionUsersResult);
        } else {
          setInstitutionUser(null);
          setInstitutionUsers([]);
        }
      }).catch(error => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
        console.log("GetInstitutionUsers 1", error);
      }).finally(() => {
        setIsInstitutionUsersDataLoaded(true);
      });
    }

    async function GetStudentUsers(opeId: string | undefined) {
      await orgService.GetStudentUsers(opeId, (v) => v).then(result => {
        if (result) {
          const studentUsersResult = (result as iStudentUserData[]).map(user => ({
              ...user,
              // status: 'Active' // FUTURE: Remove hardcoded value when status is available in the API
              status: user.statusId === 1 ? 'Active' : 'Inactive'
          }));

          if (authId) {
            const user = studentUsersResult.find(user => user.authId === authId);
            if (user) setStudentUser(user);
          }
          setStudentUsers(studentUsersResult);
        } else {
          setStudentUser(null);
          setStudentUsers([]);
        }
      }).catch(error => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
        console.log("GetStudentUsers 1", error);
      }).finally(() => {
        setIsStudentUsersDataLoaded(true);
      });
    }

    if (organization?.opeid) {
      if (selectedUserType === 'Institution' && !isInstitutionUsersDataLoaded) {
        GetInstitutionUsers(organization.opeid);
      } else if (selectedUserType === 'Students' && !isStudentUsersDataLoaded) {
        GetStudentUsers(organization.opeid);
      }
    }
  }, [organization, selectedUserType, isInstitutionUsersDataLoaded, isStudentUsersDataLoaded, authId, enqueueSnackbar]);

  return (
    <AdminUsersDataContext.Provider value={{
      institutionUser: institutionUser,
      setInstitutionUser: setInstitutionUser,
      institutionUsers: institutionUsers,
      setInstitutionUsers: setInstitutionUsers,
      isInstitutionUsersDataLoaded: isInstitutionUsersDataLoaded,
      setIsInstitutionUsersDataLoaded: setIsInstitutionUsersDataLoaded,

      studentUser: studentUser,
      setStudentUser: setStudentUser,
      studentUsers: studentUsers,
      setStudentUsers: setStudentUsers,
      isStudentUsersDataLoaded: isStudentUsersDataLoaded,
      setIsStudentUsersDataLoaded: setIsStudentUsersDataLoaded,

      selectedUserType: selectedUserType,
      setSelectedUserType: setSelectedUserType
    }}>
      {children}
    </AdminUsersDataContext.Provider>
  );
};
