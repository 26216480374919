import { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Container, Grid, Link, ListItemText, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { visuallyHidden } from '@mui/utils';
import { UserContext } from '../contexts/UserContext';
import * as yup from 'yup';
import { useFormik } from "formik";
import { formatDate, formatPhoneNumber, formatSSN } from '../utilities/HelperFunctions';
import ConfirmationDialog from './ConfirmationDialog';
import KeycloakService from '../services/KeycloakService';
import userService from '../services/UserService';
import { iUserDTO } from '../utilities/APIInterfaces';
import CustomTextField from './CustomTextField';

const tableSX = {
  tableHead: {
    fontSize: '0.875rem',
    fontWeight: '500',
    px: 2,
    pt: 0,
    pb: 1.25,
    textTransform: 'uppercase'
  },
  tableCell: {
    px: 2,
    py: 1
  }
};

export default function ProfileForm() {
  const userContext = useContext(UserContext);

  const changeEmailUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/`
  + `realms/${process.env.REACT_APP_KEYCLOAK_REALM}/`
  + `protocol/openid-connect/auth`
  + `?client_id=${process.env.REACT_APP_KEYCLOAK_DASHBOARD_CLIENT}`
  + `&redirect_uri=${encodeURIComponent(window.location.href.split('?')[0])}`
  + `&response_mode=query`
  + `&response_type=code`
  + `&scope=openid`
  + `&kc_action=UPDATE_PROFILE`;

  const changePhoneNumberUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/`
  + `realms/${process.env.REACT_APP_KEYCLOAK_REALM}/`
  + `protocol/openid-connect/auth`
  + `?client_id=${process.env.REACT_APP_KEYCLOAK_DASHBOARD_CLIENT}`
  + `&redirect_uri=${encodeURIComponent(window.location.href.split('?')[0])}`
  + `&response_mode=query`
  + `&response_type=code`
  + `&scope=openid`
  + `&kc_action=mobile_number_config`;


  const [isSaving, setIsSaving] = useState(false);

  // Action menu under the "..." button in the "Associated Institutions" table
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isActionMenuOpen = Boolean(anchorEl);

  type PhoneDialogOptionsType = {
    isOpen: boolean;
    isWorking: boolean;
  };
  const [phoneDialogOptions, setPhoneDialogOptions] = useState({} as PhoneDialogOptionsType);

  type InstitutionDialogOptionsType = {
    isOpen: boolean;
    isWorking: boolean;
    institutionId: number;
    studentId: string | null;
  }
  const [institutionDialogOptions, setInstitutionDialogOptions] = useState({} as InstitutionDialogOptionsType);

  const initialValues = {
    // userContext.user is null when this page first loads...the useEffect will populate the initialValues once userContext.user is set
    firstName: userContext.user?.firstName || 'Loading...',
    lastName: userContext.user?.lastName || ''
  };
  const validationSchema = yup.object({
    firstName: yup.string().trim().required('First name is required'),
    lastName: yup.string().trim().required('Last name is required')
  });
  const basicInfoFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => SaveChanges(values)
  });

  const phoneFormik = useFormik({
    initialValues: { phoneDialog: userContext.user?.phone || ''},
    validationSchema: yup.object({
      phoneDialog: yup.string().required('Mobile number is required').min(10, 'Mobile number must be 10 digits')
    }),
    onSubmit: values => {
      setPhoneDialogOptions({ ...phoneDialogOptions, isWorking: true });
      const userObj = userService.MapToDto({
        ...userContext.user,
        phone: values.phoneDialog
      });
      userService.UpdateUser(userService.MapToDto(userContext.user), userObj, (v) => v)
        .then(() => userContext.setUser(userService.MapToUser(userObj)))
        .finally(() => setPhoneDialogOptions({ ...phoneDialogOptions, isOpen: false, isWorking: false }));
    }
  });

  useEffect(() => {
    if (userContext.user) {
      /*
        If Keycloak email doesn't match userContext.user.email (e.g database value), update the database.
        Assumption: User changed email address via the "Edit" link and are now "returning" to the app.
      */
      if (KeycloakService.getTokenParsed()?.email !== userContext.user.email) {
        const userObj = userService.MapToDto({
          ...userContext.user,
          email: KeycloakService.getTokenParsed()?.email
        });
        userService.UpdateUser(userService.MapToDto(userContext.user), userObj, (v) => v).then(() => userContext.setUser(userService.MapToUser(userObj)));
      }

      basicInfoFormik.setValues({
        firstName: userContext.user.firstName || '',
        lastName: userContext.user.lastName || ''
      });
      phoneFormik.setValues({ phoneDialog: userContext.user.phone || '' });
    }
  }, [userContext]);

  // Associated Institutions changes
  useEffect(() => {
    let userObj = {} as iUserDTO;

    if (institutionDialogOptions.isWorking) {
      if (institutionDialogOptions.studentId) {
        // TRUTHY studentId means we're editing a student's Id...
        userObj = userService.MapToDto({
          ...userContext.user,
          schools: userContext.user?.schools?.map(school => {
            // Find the school that has the edited student id and update it
            if (school.id === institutionDialogOptions.institutionId) {
              return {
                ...school,
                school_Student_Id: institutionDialogOptions.studentId
              };
            } else {
              return school;
            }
          })
        });
        userService.UpdateUser(userService.MapToDto(userContext.user), userObj, (v) => v).then(() => userContext.setUser(userService.MapToUser(userObj)));
      } else {
        // ...else we're removing an institution
        userObj = userService.MapToDto({
          ...userContext.user,
          schools: userContext.user?.schools?.filter(school => school.id !== institutionDialogOptions.institutionId) || []
        });
        userService.DeleteStudentSchool(KeycloakService.getId() || null, institutionDialogOptions.institutionId, (v) => v)
          .then(() => userContext.setUser(userService.MapToUser(userObj)));
      }
      setInstitutionDialogOptions({ ...institutionDialogOptions, isOpen: false, isWorking: false });
    }
  }, [institutionDialogOptions]);


  const SaveChanges = async (formData: any) => {
    setIsSaving(true);

    const userObj = userService.MapToDto({
      ...userContext.user,
      firstName: formData.firstName,
      lastName: formData.lastName
    });

    userService.UpdateUser(userService.MapToDto(userContext.user), userObj, (v) => v)
      .then(() => userContext.setUser(userService.MapToUser(userObj)))
      .finally(() => setIsSaving(false));
  }

  const handleEditPhoneClick = () => {
    phoneFormik.setValues({ phoneDialog: userContext.user?.phone || '' });
    setPhoneDialogOptions({ ...phoneDialogOptions, isOpen: true, isWorking: false });
  };

  const handleActionButtonClick = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleActionMenuItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const id = Number(anchorEl!.getAttribute('data-row-id'));
    const action = (e.target as HTMLElement).closest('li')?.getAttribute('data-menu-action')?.toLowerCase();

    switch (action) {
      case 'edit-id-number':
        const studentId = userContext.user?.schools?.find(school => school.id === id)?.school_Student_Id || '';
        setInstitutionDialogOptions({ ...institutionDialogOptions, isOpen: true, isWorking: false, institutionId: id, studentId: studentId });
        break;
      case 'remove-institution':
        setInstitutionDialogOptions({ ...institutionDialogOptions, isOpen: true, isWorking: false, institutionId: id, studentId: null });
        break;
    }

    setAnchorEl(null);
  };

  const handleActionMenuClose = (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    setAnchorEl(null);
  };

  return (<>
    <Container sx={{ p: { xs: 2.25, sm: 4.5 } }}>

      <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5 }}>Basic Information</Typography>

      <Box component='form' noValidate onSubmit={basicInfoFormik.handleSubmit}>
        <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 6.25 }}>

          {/* First Name ------------------------------------------------------ */}
          <Grid item xs={12} md={6}>
            <CustomTextField
              autoFocus
              id='firstName'
              labelText='First Name'
              placeholder='Enter your first name'
              shrinkLabel
              value={basicInfoFormik.values.firstName}
              onBlur={basicInfoFormik.handleBlur}
              onChange={basicInfoFormik.handleChange}
              onFocus={e => e.target.select()}
              error={basicInfoFormik.touched.firstName && Boolean(basicInfoFormik.errors.firstName)}
              helperText={basicInfoFormik.touched.firstName && basicInfoFormik.errors.firstName}
            />
          </Grid>

          {/* Last Name ------------------------------------------------------- */}
          <Grid item xs={12} md={6}>
            <CustomTextField
              id='lastName'
              labelText='Last Name'
              placeholder='Enter your last name'
              shrinkLabel
              value={basicInfoFormik.values.lastName}
              onBlur={basicInfoFormik.handleBlur}
              onChange={basicInfoFormik.handleChange}
              onFocus={e => e.target.select()}
              error={basicInfoFormik.touched.lastName && Boolean(basicInfoFormik.errors.lastName)}
              helperText={basicInfoFormik.touched.lastName && basicInfoFormik.errors.lastName}
            />
          </Grid>

          {/* Date of Birth --------------------------------------------------- */}
          <Grid item xs={12} md={6}>
            <CustomTextField
              id='dob'
              labelText='Date of Birth'
              disabled
              shrinkLabel
              type='date'
              value={formatDate(userContext.user?.dob || '', false, true)}
            />
          </Grid>

          {/* Social Security Number ------------------------------------------ */}
          <Grid item xs={12} md={6}>
            <CustomTextField
              id='last4'
              labelText='Social Security Number'
              disabled
              shrinkLabel
              value={formatSSN(userContext.user?.last4 || '', true)}
            />
          </Grid>

          {/* Email Address --------------------------------------------------- */}
          <Grid item xs={12} md={6}>
            <CustomTextField
              id='email'
              labelText='Email Address'
              disabled
              shrinkLabel
              value={userContext.user?.email || ''}
            />
            <Link
              href={changeEmailUrl}
              title='Edit email address'
              sx={{
                color: 'var(--link-color)',
                cursor: 'pointer',
                display: 'inline-block',
                fontSize: '0.875rem',
                fontWeight: 600,
                mt: 1,
                textDecoration: 'none'
              }}
            >Edit</Link>
          </Grid>

          {/* Mobile Number --------------------------------------------------- */}
          <Grid item xs={12} md={6}>
            <CustomTextField
              id='phone'
              labelText='Mobile Number'
              disabled
              shrinkLabel
              value={formatPhoneNumber(userContext.user?.phone || '')}
            />
            <Link
            href={changePhoneNumberUrl}
              tabIndex={0} // Need this to get it into the tab order since it doesn't have an href
              title='Edit mobile number'
              sx={{
                color: 'var(--link-color)',
                cursor: 'pointer',
                display: 'inline-block',
                fontSize: '0.875rem',
                fontWeight: 600,
                mt: 1,
                textDecoration: 'none'
              }}
            >Edit</Link>
          </Grid>
        </Grid>

        {/* Associated Institutions ------------------------------------------- */}
        <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5 }}>Associated Institutions</Typography>

        <TableContainer sx={{ mb: 6.25 }}>
          <Table aria-label='associated institutions table'>
            {/* Empty state for associated institutions -----------------------
              Use a caption tag for the empty state to avoid this WAVE alert:
                Alerts - Possible table caption
                What It Means - Text appears to be a table caption, but is not a caption element.
            */}
            {userContext.user?.schools?.length === 0 && <caption>
                <Typography
                  variant='body1'
                  sx={{
                    color: 'grey.600',
                    fontWeight: 500,
                    py: 3.75,
                    textAlign: 'center'
                  }}
                >No associated institutions found...</Typography>
            </caption>}

            <TableHead><TableRow>
              <TableCell component='th' sx={tableSX.tableHead}>Institution</TableCell>
              <TableCell component='th' sx={tableSX.tableHead}>ID Number</TableCell>
              <TableCell component='th' sx={tableSX.tableHead}>
                {/* Fix for empty header cell accessibility error */}
                <Box sx={visuallyHidden}>Actions</Box>
              </TableCell>
            </TableRow></TableHead>
            <TableBody>
              {userContext.user?.schools?.map((school, index) => (
                <TableRow key={index}>
                  <TableCell sx={tableSX.tableCell}>{school.school_Name}</TableCell>
                  <TableCell sx={tableSX.tableCell}>{school.school_Student_Id}</TableCell>
                  <TableCell sx={{...tableSX.tableCell, pr: 0, textAlign: 'right' }}>
                    <Button
                      id={`actions-button-${school.id}`}
                      aria-controls={isActionMenuOpen ? 'more-menu' : undefined}
                      aria-expanded={isActionMenuOpen ? 'true' : undefined}
                      aria-haspopup='true'
                      aria-label='Associated institution actions options'
                      color='inherit'
                      data-row-id={school.id}
                      onClick={handleActionButtonClick}
                      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                        // Keyboard accessibility - click Action button when pressing enter or space
                        if (e.key === 'Enter' || e.key === ' ') handleActionButtonClick(e);
                      }}
                      sx={{ minWidth: 'revert' }}
                    >
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Don't want the menu to be duplicated for each row in the table */}
        <Menu
          id='actions-menu'
          anchorEl={anchorEl}
          open={isActionMenuOpen}
          onClose={handleActionMenuClose}
          MenuListProps={{
            'aria-labelledby': 'actions-button',
            dense: true,
            sx: {
              minWidth: '180px',
              textAlign: 'right'
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem data-menu-action='edit-id-number' onClick={handleActionMenuItemClick}>
            <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Edit ID Number</ListItemText>
          </MenuItem>
          <MenuItem data-menu-action='remove-institution' onClick={handleActionMenuItemClick}>
            <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Remove Institution</ListItemText>
          </MenuItem>
        </Menu>

        <Button
          disabled={isSaving}
          variant='contained'
          size='large'
          type='submit'
          sx={{
            fontSize: '1rem',
            px: 5,
            textTransform: 'none',
            bgcolor: 'InceptiaGreen.main',
            '&:hover': {
              backgroundColor: 'InceptiaGreen.dark'
            }
          }}
        >
        {isSaving ? (<>
          <CircularProgress
            color='inherit'
            size={20}
            sx={{ mr: 1 }}
          />
          Saving...
        </>) : 'Save Changes'}</Button>
      </Box>

    </Container>

    {/* Edit mobile number dialog --------------------------------------------- */}
    {phoneDialogOptions.isOpen && <ConfirmationDialog
      ariaPrefix='edit-phone-form'
      dialogTitle='Edit Mobile Number'
      singleButton={false}
      ctaButtonText='Done'
      ctaButtonWorkingText='Saving...'
      cancelButtonText='Cancel'
      open={phoneDialogOptions.isOpen}
      setOpenDialog={() => setPhoneDialogOptions({ ...phoneDialogOptions, isOpen: false })}
      isWorking={phoneDialogOptions.isWorking}
      setIsWorking={() => {
        phoneFormik.handleSubmit();
      }}
      width={528}
    >
      {/* Input field needs a label, but we don't want to see it */}
      <Typography component='label' htmlFor='phoneDialog' sx={visuallyHidden}>Phone</Typography>
      <TextField
        autoFocus
        id='phoneDialog'
        placeholder='New mobile number'
        fullWidth
        inputProps={{
          // The 10 digits formatted will = 14 - (###) ###-####
          maxLength: 14,
          // Want the input to have a height of 45px to match Figma
          sx: { py: '11px' }
        }}
        size='small'
        value={formatPhoneNumber(phoneFormik.values.phoneDialog)}
        onChange={phoneFormik.handleChange}
        onBlur={phoneFormik.handleBlur}
        onFocus={e => e.target.select()}
        error={phoneFormik.touched.phoneDialog && Boolean(phoneFormik.errors.phoneDialog)}
        helperText={phoneFormik.touched.phoneDialog && phoneFormik.errors.phoneDialog}
        sx={{
          mt: 4.5,
          // Need 40px between the input and the buttons to match Figma - dialog by default is giving us 44px in padding - so -4px bottom margin gets us there
          mb: -0.5,
          // Input's border that has an error should be red even when it has focus (without this the focused border color is the Inceptia Green)
          '& .Mui-error.Mui-focused fieldset': {
            borderColor: '#d32f2f'
          }
        }}
      />
    </ConfirmationDialog>}

    {/* Remove institution confirmation dialog -------------------------------- */}
    {institutionDialogOptions.isOpen && institutionDialogOptions.studentId === null && <ConfirmationDialog
      ariaPrefix='remove-form'
      dialogTitle='Remove Institution?'
      singleButton={false}
      ctaButtonText='Remove'
      ctaButtonWorkingText='Removing...'
      cancelButtonText='Cancel'
      open={institutionDialogOptions.isOpen}
      setOpenDialog={() => setInstitutionDialogOptions({ ...institutionDialogOptions, isOpen: false })}
      isWorking={institutionDialogOptions.isWorking}
      setIsWorking={() => setInstitutionDialogOptions({ ...institutionDialogOptions, isWorking: true })}
      width={528}
    >
      <Typography variant='body2'>This will hide any forms and verifications from the institution.</Typography>
    </ConfirmationDialog>}

    {/* Edit student Id number dialog ----------------------------------------- */}
    {institutionDialogOptions.isOpen && institutionDialogOptions.studentId !== null && <ConfirmationDialog
      ariaPrefix='edit-id-form'
      dialogTitle='Edit ID Number'
      singleButton={false}
      ctaButtonText='Save'
      ctaButtonWorkingText='Saving...'
      cancelButtonText='Cancel'
      open={institutionDialogOptions.isOpen}
      setOpenDialog={() => setInstitutionDialogOptions({ ...institutionDialogOptions, isOpen: false })}
      isWorking={institutionDialogOptions.isWorking}
      setIsWorking={() => setInstitutionDialogOptions({ ...institutionDialogOptions, isWorking: true })}
      width={528}
    >
      {/* Input field needs a label, but we don't want to see it */}
      <Typography component='label' htmlFor='student-id' sx={visuallyHidden}>Student Id</Typography>
      <TextField
        autoFocus
        id='student-id'
        placeholder='Enter student ID number'
        fullWidth
        inputProps={{
          // !!! student_school.SCHOOL_STUDENT_ID = varchar(10)
          maxLength: 10,
          // Want the input to have a height of 45px to match Figma
          sx: { py: '11px' }
        }}
        size='small'
        value={institutionDialogOptions.studentId ?? ''}
        onChange={e => setInstitutionDialogOptions({ ...institutionDialogOptions, studentId: e.target.value })}
        onFocus={e => e.target.select()}

        // Need 40px between the input and the buttons to match Figma - dialog by default is giving us 44px in padding - so -4px bottom margin gets us there
        sx={{ mt: 4.5, mb: -0.5 }}
      />
    </ConfirmationDialog>}

  </>);
}