import { enqueueSnackbar } from "notistack";
import DashboardAPI from "../apis/DashboardAPI";
import { iOrgDTO, iUserServiceHistoryCommentDTO, iSubmission, iUserDTO, iUserServiceHistoryDTO, iUserLink, iKeyCloakUserRequestDTO } from "../utilities/APIInterfaces";
import { GeneratePatchCmds } from "../utilities/HelperFunctions";
import { iUser } from "../contexts/UserContext";
import { MfaType, ServiceHistoryType } from "../utilities/FormEnums";


class UserService {
    async SendReminder(authId: string, userLinkUUID: string, formUuid: string | undefined) {
      let result = await DashboardAPI.callForData<iUserLink>({
        method: "POST",
        route: `${this.GetAPIPrefix(authId)}/relationship/SendContributorReminder`,
        body: {
          userLinkUUID: userLinkUUID,
          formUuid: formUuid
        }
      })
      return result;
    }
    async CreateUserRelationship(authId: string, contributorEmail: string, requestedBy: string) : Promise<iUserLink> {
        let result = await DashboardAPI.callForData<iUserLink>({
            method: "POST",
            route: `${this.GetAPIPrefix(authId)}/relationship`,
            body: {
                sendInviteTo: contributorEmail,
                requestedBy: requestedBy
            }
        })
        return result;
    }
    async GetUserLinks(authId: string): Promise<iUserLink[]> {
      
      let userLinks = DashboardAPI.callForData<iUserLink[]>({
        method: "GET",
        route: `${this.GetAPIPrefix(authId)}/relationship`
      });
      return userLinks;
    }
    //todo, maybe clean up the return. Quick and dirty for now to get it through the sprint
    async LinkUserRelationship(authId: string | undefined, userLinkUUID: string | undefined, ssn: string, dob: string) : Promise<[boolean,string]>  {
        let result =  await DashboardAPI.call({
            method: "PATCH",
            route: `${this.GetAPIPrefix(authId)}/Relationship/${userLinkUUID}`,
            body: {
                ssn: ssn,
                dob: dob
            }
            
        });
        var message="";
        await result.text().then((text) => message = text)
        return [result.ok, message];

        
    }
    async GetUserLink(studnetLinkUUID: string, setLoading: (value: boolean) => void) : Promise<iUserLink> {
        setLoading(true);
        let userLink = await DashboardAPI.callForData<iUserLink>({
            method: "GET",
            route: `${this.GetAPIPrefix()}/Relationship/${studnetLinkUUID}`
        });
        setLoading(false);
        return userLink;
    }
    async ForgetCredential(authId: string | undefined, credentialId: string,  loader: (v: boolean) => void) {
        loader(true);
        let forceSetup =  await DashboardAPI.callForData<boolean>({
            method: "DELETE",
            route: `${this.GetAPIPrefix(authId)}/Credential/${credentialId}`
        });
        loader(false);
        return forceSetup;
    }
  async UpdateMfaPreference(authId: string | undefined ,mfaChoice: MfaType, loader: (v: boolean) => void) {
      try {
          loader(true);
           await DashboardAPI.call({
                method: "PUT",
                route: `${this.GetAPIPrefix(authId)}/UpdateMFAPreference/${mfaChoice}`,
           })
      } catch (error) {
          console.log(`${UserService.name} catch`, error);
      } finally {
          loader(false);
          
      }
  }
    async GetUser(authId: string | undefined,  loader: (v: boolean) => void, displayErrorMessage=true) : Promise<iUserDTO> {
        let user = {} as iUserDTO;

        try {
          loader(true);
          user = await DashboardAPI.callForData<iUserDTO>({
            method: "GET",
            route: this.GetAPIPrefix(authId)
          }, displayErrorMessage);
        } catch (error) {
          console.log(`${UserService.name} catch`, error);
        } finally {
          loader(false); 
          return user;
        }
    }

    async GetUsers() : Promise<iUserDTO[]> {
        let users = await DashboardAPI.callForData<iUserDTO[]>({
            method: "GET",
            route: this.GetAPIPrefix()
        });
        return users;
    }

    async CreateUser(user: iUserDTO,  loader: (v: boolean) => void) {
        let newUser = null;
        if(user !== undefined) { //coersion with != intentional to account for null
            loader(true);
            await DashboardAPI
              .callForData<iUserDTO>({
                  method: "POST",
                  route: this.GetAPIPrefix(),
                  body: user
              })
              .then((result) => user = result)
              .catch((e) => {
                const errMessage = e.message ? e.message : "Information may match an existing account. Please double check what you have entered in. If you are still having issues please contact support.";
                throw new Error(errMessage);
              });
            loader(false)
        }

        return newUser;
    }

    async UpdateUser(oldData: iUserDTO, newData: iUserDTO,  loader: (v: boolean) => void) {
        let commands = GeneratePatchCmds(oldData, newData);
        loader(true);
        await DashboardAPI.call({
            method: "PATCH",
            route: this.GetAPIPrefix(oldData.auth_Id), 
            body: commands
        });
        loader(false);
    }

    async CreateKeyCloakUser(user: iKeyCloakUserRequestDTO,  loader: (v: boolean) => void) {
        if(user !== undefined) { //coersion with != intentional to account for null
            loader(true);
            await DashboardAPI
                .callForData<iKeyCloakUserRequestDTO>({
                    method: "POST",
                    route: `${this.GetAPIPrefix()}/KeyCloak`,
                    body: user
                });
            loader(false)
        }
    }

    /**
     * Retrieves form data for specified user to be used in the student Add Form Modal (AFM).
     *
     * @param {string | undefined} authId - The ID of the user.
     * @param {(v: boolean) => void} loader - A function to toggle the loading state.
     * @return {Promise<any>} A Promise that resolves with the form data.
     */
    async GetUserForms(authId: string | undefined,  loader: (v: boolean) => void) {
        let formData = null;
        if(authId != null) {
            loader(true);
            formData = await DashboardAPI.callForData({
                method: "GET",
                route: `${this.GetAPIPrefix(authId)}/Form`});
            loader(false); 
        }
        return formData;
    }

    /**
     * Retrieves organization data for specified user.
     *
     * @param {string | undefined} authId - The Auth ID of the user.
     * @param {(v: boolean) => void} loader - A function to toggle the loading state.
     * @return {Promise<any>} A Promise that resolves with the organization data.
     */
    async GetOrgsByUser(authId: string | undefined,  loader: (v: boolean) => void, displayErrorMessage = true): Promise<iOrgDTO[]> {
        loader(true);
        let orgs = await DashboardAPI.callForData<iOrgDTO[]>({
            method: "GET",
            route: `${this.GetAPIPrefix(authId)}/Organization`
        },displayErrorMessage);
        loader(false);
        return orgs;
    }

    async AssignFormPurlToUser(formUuid: string, orgPurl: string, authId: string | undefined,  loader: (v: boolean) => void) {
        loader(true);
        let result = await DashboardAPI.call({
            method: "POST",
            route: `${this.GetAPIPrefix(authId)}/Form/AssignFormPurlToUser/`,
            body: {
                formUuid: formUuid,
                orgPurl: orgPurl
            }
        });
        if(!result.ok) {
            enqueueSnackbar("Unable to process request. Please use the requested link provided by your school", { variant: "error" });
        } 
    }
    async CreateDevice(authId: string | undefined,  deviceId: string, loader: (v: boolean) => void) {
        loader(true);
        await DashboardAPI.call({
            method: "POST",
            route: `${this.GetAPIPrefix(authId)}/Device`,
            body: {
                auth_Id: authId,
                device_Id: deviceId
            }
        });
        loader(false);
    }

    async GetYourContacts(authId: string,  loader: (v: boolean) => void) {
        let contacts = null;
        if (authId) {
          loader(true);
          contacts = await DashboardAPI.callForData({
              method: "GET",
              route: `Salesforce/User/${authId}/Contacts`
          });
          loader(false);
        }
        return contacts;
    }

    async GetUserSubmission(authId: string | undefined, submissionUuid: string | undefined,  loader: (v: boolean) => void) {
        let submissionData = null
        if(authId && submissionUuid) {
            loader(true);
            submissionData = await DashboardAPI.callForData<iSubmission>({
              method: "GET",
              route: `${this.GetAPIPrefix(authId)}/SubmissionForm/${submissionUuid}`
            });
            loader(false); 
        }

        return submissionData;
    }

    async GetUserServiceHistory(authId: string | undefined, submissionUuid: string | undefined,  loader: (v: boolean) => void): Promise<iUserServiceHistoryDTO[]> {
      let serviceHistoryData: iUserServiceHistoryDTO[] = [];
      if(authId && submissionUuid) {
          loader(true);
          serviceHistoryData = await DashboardAPI.callForData<iUserServiceHistoryDTO[]>({
            method: "GET",
            route: `${this.GetAPIPrefix(authId)}/SubmissionForm/${submissionUuid}/ServiceHistory`
          });
          loader(false); 
      }

      return serviceHistoryData;
    }

  async GetUserServiceHistoryItem(authId: string, id: number, type: ServiceHistoryType, loader: (v: boolean) => void): Promise<iUserServiceHistoryDTO> {
    let serviceHistoryItem: iUserServiceHistoryDTO = {} as iUserServiceHistoryDTO;

    if (authId && id && type) {
        loader(true);
        serviceHistoryItem = await DashboardAPI.callForData<iUserServiceHistoryDTO>({
          method: "GET",
          route: `${this.GetAPIPrefix(authId)}/ServiceHistoryItem?id=${id}&type=${type}`
        });
        loader(false); 
    }

    return serviceHistoryItem;
  }

  async CreateServiceHistoryComment(authId: string, comment: iUserServiceHistoryCommentDTO, loader: (v: boolean) => void) {
    let response = null;

    if (authId && comment.submission_Forms_Uuid) {
      loader(true);
      response = await DashboardAPI.callForData({
        method: 'POST',
        route: `${this.GetAPIPrefix(authId)}/ServiceHistoryComment`,
        body: comment
      });
      loader(false)
    }

    return response;
  }

  async DeleteServiceHistoryComment(authId: string, commentId: number, loader: (v: boolean) => void) {
    let response = null;

    if (authId && commentId) {
      loader(true);
      response = await DashboardAPI.callForData({
        method: 'DELETE',
        route: `${this.GetAPIPrefix(authId)}/ServiceHistoryComment/${commentId}`
      });
      loader(false)
    }

    return response;
  }

    /**
   * Saves the selected forms from the Add Form Modal (AFM) for the specified user.
   *
   * @param {Array<{forms_Id: number}>} userForms - An array of user form objects.
   * @param {string | null} authId - The Auth ID of the user. Can be null.
   * @param {(v: boolean) => void} loader - A function to control the loading state.
   * @return {Promise<any> | null} - The response from the API call, or null if authId is null.
   */
  async CreateUserForm(userForms: {forms_Id: number}[], authId: string | undefined,  loader: (v: boolean) => void) {
    let response = null;

    if (authId !== null) {
      loader(true);
      response = await DashboardAPI.callForData({
        method: 'POST',
        route: `${this.GetAPIPrefix(authId)}/Form`,
        body: userForms
      });
      loader(false)
    }

    return response;
  }

  /**
   * Removes specified form for the specified user from the To-Do List on the student dashboard.
   *
   * @param {number} forms_Id - The Form ID of the form to be removed.
   * @param {string | null} authId - The Auth ID of the user. Can be null.
   * @param {(v: boolean) => void} loader - A function to control the loading state.
   * @return {Promise<any> | null} The response from the API call, or null if authId is null.
   */
  async RemoveUserForm(forms_Id: number, forms_Submission_Id: number | null, authId: string | null,  loader: (v: boolean) => void) {
    let response = null;

    if (authId !== null) {
      loader(true);
      response = await DashboardAPI.callForData({
        method: 'DELETE',
        route: `${this.GetAPIPrefix(authId)}/Form`,
        body: {
          forms_Id: forms_Id,
          forms_Submission_Id: forms_Submission_Id
        }
      });
      loader(false)
    }

    return response;
    }

  async DeleteStudentSchool(authId: string | null, Id: number, loader: (v: boolean) => void) {
    let response = null;

    if (authId !== null) {
      loader(true);
      response = await DashboardAPI.callForData({
        method: 'DELETE',
        route: `${this.GetAPIPrefix(authId)}/School/${Id}`,
        body: Id
      });
      loader(false)
    }

    return response;
  }
  
    async AssignToOrg(purl: string, authID: string | undefined,  loader: (v: boolean) => void) {
        loader(true);
        let result = await DashboardAPI.call({
            method: "POST",
            route: `${this.GetAPIPrefix()}/AssignToOrg/`,
            body: {
                purl: purl,
                authId: authID
            }
        });
        loader(false);
        //if it's successful, check to make sure we actually assigned to an org
        if (result.status === 201) 
        {
            enqueueSnackbar("Successfully assigned to organization", { variant: "success" });
        } else if (result.status === 404) {
            enqueueSnackbar("Unable to process request.Please use the requested link provided by your school", { variant: "error" });
        }
    }

    MapToUser(dto: iUserDTO | null): iUser | null {
        return dto !== null ? {
            id: dto.id,
            authId: dto.auth_Id,
            email: dto.email,
            firstName: dto.first_Name,
            lastName: dto.last_Name,
            phone: dto.phone,
            profileImage: null,
            smsConsent: dto.sms_Consent,
            ssnDobSet: dto.ssn_Dob_Set,
            ssn: dto.ssn,
            last4: dto.last4,
            dob: dto.dob,
            schools: dto.studentSchools,
            mfaOptions: dto.mfaOptions
        } : null;
    }

    MapToDto(user: any): iUserDTO {
        let userDTO: iUserDTO = {
            id: user?.id || 0,
            auth_Id: user?.authId || "",
            email: user?.email || "",
            first_Name: user?.firstName || "",
            last_Name: user?.lastName || "",
            phone: user?.phone || "",
            ssn: user?.ssn || "",
            last4: user?.last4 || "",
            dob: user?.dob || "",
            // This is ugly, but smsConsent can be a bool or string depending on source
            sms_Consent: user?.smsConsent.toString() === "true",
            ssn_Dob_Set: user?.ssnDobSet.toString() === "true",
            studentSchools: user?.schools,
            mfaOptions : user?.mfachoice
        };
        return userDTO;
    }

    GetAPIPrefix(authId: string = ""): string {
        let apiPrefix: string = 'User';
        if (authId) {
            return `${apiPrefix}/${authId}`;
        }
        return apiPrefix;
    }
}

const userService = new UserService()
export default userService;