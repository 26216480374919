import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import KeycloakService from './services/KeycloakService';
import { OrganizationContextProvider } from './contexts/OrganizationContext';
import { UserContextProvider } from './contexts/UserContext';
import { BrowserRouter } from 'react-router-dom';
import { Key } from '@mui/icons-material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
KeycloakService.initKeycloak(() => {
  if(KeycloakService.isLoggedIn()) {
    if(KeycloakService.getGroups().length > 0) {
      if(KeycloakService.getOrgs().length === 0) {
        window.location.href = process.env.REACt_APP_CLASSIC_DASHBOARD_URL || 'https://dashboard.inceptia.org';
      }
    }
  }
  root.render(
    // <React.StrictMode>
    <OrganizationContextProvider>
      <UserContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UserContextProvider>
    </OrganizationContextProvider>
    // </React.StrictMode>
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
