import { Autocomplete,  TextField, Typography  } from "@mui/material";
import { iTodo, iUserLink } from "../utilities/APIInterfaces";
import { useContext, useEffect, useRef, useState } from "react";
import userService from "../services/UserService";
import ConfirmationDialog from "./ConfirmationDialog";
import * as yup from 'yup';
import {  useFormik } from "formik";
import CustomTextField from "./CustomTextField";
import { enqueueSnackbar } from "notistack";
import { UserContext } from "../contexts/UserContext";


type ContributorDialogProps = {
    uuid: string;
    setOpenDialog: (value: boolean) => void;
    onClose: () => void;
    todo?: iTodo;
}
export default function ContributorDialog(props: ContributorDialogProps) {
    const [userLinks,setUserLinks] = useState([] as iUserLink[]);
    const [isWorking, setIsWorking] = useState(false);
    const { user } = useContext(UserContext);

    const validationSchema = yup.object().shape({
        type: yup.number().required("Please select a parent/guardian"),
        userLinkUUID: yup.string(),
        contributorEmail: yup.string().email("Please enter a valid email")
        .notOneOf([
            user?.email
        ],"You cannot invite yourself")
        .when('type', {
            is: (val: number) => val === 1,
            then: (schema) => schema.required("Email is required")
        })
    });
    const formik = useFormik({
        initialValues: {
            type:null,
            userLinkUUID: "",
            contributorEmail: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(values.type === null){
                setIsWorking(false);
                handleClose();
                return;
            };
            if (values.type==1 && values.contributorEmail) {
                userService.CreateUserRelationship(props.uuid, values.contributorEmail, "user").then((result : iUserLink) => {
                    enqueueSnackbar("Invite sent", { variant: 'success' });
                    setIsWorking(false);
                    handleClose();
                });
            }else if (values.type==2 && values.userLinkUUID) {
                userService.SendReminder(props.uuid,values.userLinkUUID,props.todo?.formUuid).then(() => {
                    enqueueSnackbar("Reminder sent", { variant: 'success' });
                    setIsWorking(false);
                    handleClose();
                });
            }
            //this prevents a double submit on close
            handleClose()
        }
    });

    let handleClose = () => {
        props.onClose();
    }
    

    useEffect(() => { 
        //create a default contributor option
        let defaultContributorOption: iUserLink = {
            contributorName: "",
            contributorEmail: "", 
            contributorId: -1,
            Id: -1,
            userLinkUUID: "-1",
            status: "",
            createdOn: new Date(),
            userIsContributor: false,
            emailSentTo: ""
        };
        let userLinks : iUserLink[] = [];
        userService.GetUserLinks(props.uuid).then((data : iUserLink[]) => {
            data.forEach((link) => {
                if(link.status === "Approved" && !link.userIsContributor){
                    userLinks.push(link);
                }
            });
            if (!userLinks.includes(defaultContributorOption)) {
                userLinks.push(defaultContributorOption);
            }
        });
        setUserLinks(userLinks);
    }, [props.uuid]);

    return (
        <ConfirmationDialog
            ariaPrefix="user-link"
            dialogTitle="Invite Parent/Guardian"
            singleButton={false}
            ctaButtonText={formik.values.type ===2 ? "Send Reminder" : "Send Invite"}
            ctaButtonWorkingText={"Sending"}
            open={true}
            setOpenDialog={props.setOpenDialog}
            isWorking={isWorking}
            setIsWorking={(isWorking) => {
                if(isWorking){
                    formik.handleSubmit();  
                }
            }
            }
        >
            {!props.todo && <Typography variant='body1'>You can invite a parent/guardian. Send an email invite to get them started. </Typography>}
            {props.todo && <Typography variant='body1'><b>{props.todo?.name}</b> requires a parent/guardian to add information. Send an email invite to get them started. </Typography>}
            <Autocomplete
            id="user-link-autocomplete"
            options={userLinks}
            getOptionLabel={(option) => option.Id === -1 ? "Invite New Parent/Guardian" : `${option.contributorName} (${option.contributorEmail})`}
            disableClearable={true}
            disabled={isWorking}
            loading={userLinks.length === 0}
            
            onChange={(e, value: iUserLink) => {
                // setCurrentContributor(value);
                if(value.Id === -1){
                    formik.setFieldValue('type',1);
                }
                else{
                    formik.setFieldValue('userLinkUUID', value.userLinkUUID); 
                    formik.setFieldValue('type',2);
                }
              }}
            multiple={false}
                renderInput={(params) =>
                    <CustomTextField
                        // inputRef={textRef}
                        {...params}
                        labelText='Choose Parent/Guardian'
                        error={formik.touched.userLinkUUID && Boolean(formik.errors.userLinkUUID)}
                        helperText={formik.touched.userLinkUUID && formik.errors.userLinkUUID}
                    />}
                sx={{ width: '98%' }}
            />
            <CustomTextField
                id='contributorEmail'
                style={{ display: formik.values.type === 1 ? 'block':'none'  }}
                labelText="Parent/Guardian Email Address"
                variant="outlined"
                disabled={isWorking}
                fullWidth
                margin="normal"
                sx={{ width: '98%' }}
                value={formik.values.contributorEmail || ''}
                error={formik.touched.contributorEmail && Boolean(formik.errors.contributorEmail)}
                helperText={formik.touched.contributorEmail && formik.errors.contributorEmail}
                onChange={(e) => {
                    formik.setFieldValue('contributorEmail', e.target.value);
                }}
            
            />
        </ConfirmationDialog>
    )
}